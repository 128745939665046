import { defaultTimer } from '../constants';
import { defaultTimer } from '../constants';
<template>
  <div>
    <b-card>
      <template #header>
        FBA Shipments Tracking
        <span class="float-right">
          <small class="mr-1">Auto-refresh in {{ (timer/1000) }} seconds.</small>
          <b-button class="mr-1" size="sm" variant="secondary" @click="loadItems">Refresh</b-button>
          <b-button size="sm" variant="primary" @click="importModal = true">Import</b-button>
        </span>
      </template>
      <b-card-text>
        <div class="table">
          <b-table
          id="fba-shipments-table"
          class="border-bottom"
          show-empty
          :empty-text="tableLoading ? 'Getting Data...' : 'There are no records to show'"
          :busy="tableLoading"
          :items="items"
          :fields="fields"
          :per-page="pagination.perPage"
          :current-page="pagination.currentPage"
          hover>
            
            <template #cell(createdAt)="data">
              {{ data.item.createdAt }} <small v-if="data.item.timeFromNow"><em>({{ data.item.timeFromNow }})</em></small>
            </template>

            <template #cell(statusJob)="data">
              <div class="d-flex align-items-center">
                <b-badge v-if="data.item.statusJob === 0" variant="secondary" class="py-2" style="width: 5rem;">Open</b-badge>
                <b-badge v-if="data.item.statusJob > 0 && data.item.statusJob < 100" variant="warning" class="py-2" style="width: 5rem;">{{ data.item.statusJob.toFixed(2) }}%</b-badge>
                <b-badge v-if="data.item.statusJob === 100" variant="success" class="py-2" style="width: 5rem;">Complete</b-badge>
                <i v-if="data.item.statusJob > 0 && data.item.statusJob < 100" class="ml-1 fas fa-circle-notch fa-spin fa-lg"></i>
              </div>
            </template>

            <template #cell(actions)="data">
              <b-button class="mr-1" size="sm" variant="primary" @click="onViewResults(data.item)">View</b-button>
              <b-button size="sm" variant="primary" @click="onDirectDownload(data.item)">Download</b-button>
            </template>

          </b-table>
          <b-pagination
          :disabled="tableLoading"
          v-if="items.length > 0"
          v-model="pagination.currentPage"
          :total-rows="pagination.totalRows"
          :per-page="pagination.perPage"
          aria-controls="fba-shipments-table"
          align="center"
          first-number
          last-number
          hide-goto-end-buttons
          ></b-pagination>
        </div>
      </b-card-text>
    </b-card>

    <b-modal v-model="importModal" title="Import" hide-footer no-close-on-backdrop @close="onImportCancel">
      <b-form-group id="form-inputs" label="Enter Shipment ID | No of SKUs | No of Units">
        <small>Paste your data here...</small>
        <div style="max-height:500px;overflow-y:auto;">
          <hot-table :settings="hotSettings"></hot-table>
        </div> 
      </b-form-group>
      
      <b-form-group class="border-top mb-n1">
        <label class="mt-1">Total Valid: {{importList.length}}</label>
        <div class="controls float-right mt-2">
          <b-button :disabled="importLoading" class="mr-1" size="sm" variant="secondary" @click="onImportCancel">Cancel</b-button>
          <b-button :disabled="importLoading" size="sm" variant="primary" @click="onImportSubmit">Submit</b-button>
        </div>
      </b-form-group>
    </b-modal>

    <b-modal v-model="resultModal" :title="`${resultModalData && (resultModalData.batchId)} (${resultModalData ? resultModalData.total : 0})`" size="xl" hide-footer no-close-on-backdrop>
      <b-card-text>
        <div class="mb-2 d-flex justify-content-between align-items-center">
          <div class="filter">
            <b-input v-model="resultModalSearch" type="search" placeholder="Search"></b-input>
          </div>
          <div class="controls">
            <b-button :disabled="directDownloadLoading" class="mr-1" size="sm" variant="success" @click="onCopyToClipboard">Copy to Clipboard</b-button>
            <b-button :disabled="directDownloadLoading" size="sm" variant="primary" @click="onDownloadResult">{{ directDownloadLoading ? 'Downloading' : 'Download' }}</b-button>
          </div>
        </div>
        <div class="table fba-shipment">
          <b-table
          id="result-table"
          class="border-bottom"
          :filter="resultModalSearch"
          show-empty
          :empty-text="resultModalLoading ? 'Loading Result...' : 'There are no records to show'"
          :busy="resultModalLoading"
          :items="resultModalItems"
          :fields="resultModalFields"
          :per-page="resultModalPagination.perPage"
          :current-page="resultModalPagination.currentPage"
          small
          table-class="text-nowrap" responsive
          hover>
            <template #cell(status)="data">
              <i v-if="data.item.status === 'done'" class="fa fa-check-circle text-success px-1"></i>
            </template>

            <template #cell(noOfSkus)="data">
              {{ data.item.noOfSkus }} 
              <b-badge v-if="data.item.skuStatus === 'Matched' && data.item.status === 'done'" variant="success">Matched</b-badge>
              <b-badge v-if="data.item.skuStatus === 'Not Matched' && data.item.status === 'done'" variant="danger">Not Matched</b-badge>
            </template>

            <template #cell(noOfUnits)="data">
              {{ data.item.noOfUnits }} 
              <b-badge v-if="data.item.unitStatus === 'Matched' && data.item.status === 'done'" variant="success">Matched</b-badge>
              <b-badge v-if="data.item.unitStatus === 'Not Matched' && data.item.status === 'done'" variant="danger">Not Matched</b-badge>
            </template>
          </b-table>
          <b-pagination
          :disabled="resultModalLoading"
          v-if="resultModalItems.length > 0"
          v-model="resultModalPagination.currentPage"
          :total-rows="resultModalPagination.totalRows"
          :per-page="resultModalPagination.perPage"
          aria-controls="result-table"
          align="center"
          first-number
          last-number
          hide-goto-end-buttons
          ></b-pagination>
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { defaultTimer } from '../constants'
import api from '../services/apiService'
import { generateExcel, generateDefaultFBAShipmentSheets } from '../services/excelService'
import { HotTable } from '@handsontable/vue';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.full.css'
registerAllModules();

let defaultSheets = generateDefaultFBAShipmentSheets(100);

export default {
  defaultSheets,
  components: {
    HotTable
  },
  data(){
    return {
      hotSettings: {
        data: JSON.parse(JSON.stringify(defaultSheets)),
        colHeaders: ['Shipment ID', 'No of SKUs', 'No of Units'],
        rowHeaders: true,
        colWidths: 138,
        width: '100%',
        height: 'auto',
        afterChange: this.onSheetChange,
        licenseKey: 'non-commercial-and-evaluation'
      },
      
      //import modal
      importModal: false,
      importLoading: false,
      importList: [],

      //table
      items: [],
      fields: [
        {
          key: "createdAt",
          label: "Requested Date",
          class: 'align-middle'
        },
        {
          key: "requestor",
          label: "Requested By",
          class: 'align-middle'
        },
        {
          key: "batchId",
          label: "Batch ID",
          class: 'align-middle'
        },
        {
          key: "total",
          label: "Total Items",
          class: 'align-middle'
        },
        {
          key: "statusJob",
          label: "Status",
          class: 'align-middle'
        },
        {
          key: "actions",
          label: "Actions",
          class: 'align-middle'
        }
      ],
      tableLoading: false,
      pagination: {
        currentPage: 1,
        perPage: 15,
        totalRows: 0
      },

      //result modal
      resultModal: false,
      resultModalSearch: '',
      resultModalData: null,
      resultModalItems: [],
      resultModalFields: [
        {
          key: "status",
          label: "",
          class: 'align-middle'
        },
        {
          key: "shipmentId",
          label: "Shipment ID",
          class: 'align-middle'
        },
        {
          key: "noOfSkus",
          label: "No of SKUs",
          class: 'align-middle'
        },
        {key: 'skuStatus', label: "SKU Status", thClass: 'd-none', tdClass: 'd-none' },
        {
          key: "noOfUnits",
          label: "No of Units",
          class: 'align-middle'
        },
        {key: 'unitStatus', label: "Unit Status", thClass: 'd-none', tdClass: 'd-none' },
        {
          key: "shipmentName",
          label: "Shipment Name",
          class: 'align-middle'
        },
        {
          key: "name",
          label: "Name",
          class: 'align-middle'
        },
        {
          key: "address1",
          label: "Address 1",
          class: 'align-middle'
        },
        {
          key: "address2",
          label: "Address 2",
          class: 'align-middle'
        },
        {
          key: "city",
          label: "City",
          class: 'align-middle'
        },
        {
          key: "state",
          label: "State",
          class: 'align-middle'
        },
        {
          key: "zipCode",
          label: "Zip Code",
          class: 'align-middle'
        },
        {
          key: "country",
          label: "Country",
          class: 'align-middle'
        },
        {
          key: "phoneNumber",
          label: "Phone No.",
          class: 'align-middle'
        },
        {
          key: "shippingService",
          label: "Shipping Service",
          class: 'align-middle'
        },
        {
          key: "shippingServiceLevel",
          label: "Shipping Service Level",
          class: 'align-middle'
        },
        {
          key: "shipToCode",
          label: "Ship To Code",
          class: 'align-middle'
        },
      ],
      resultModalLoading: false,
      resultModalPagination: {
        currentPage: 1,
        perPage: 15,
        totalRows: 0
      },

      directDownloadLoading: false,
      timer: defaultTimer
    }
  },
  created(){
    this.loadItems();
    setInterval(() => {
      this.timer = this.timer - 1000
      if((this.timer/1000) === 0){
        this.loadItems()
        this.timer = defaultTimer
      }
    }, 1000)
  },
  methods: {
    onImportCancel(){
      this.importModal = false
      this.importList = []
      this.hotSettings.data = JSON.parse(JSON.stringify(defaultSheets))
    },
    onSheetChange(change){
      if(change){
        this.importList = []
        let rows = this.hotSettings.data
        for(let x in rows){
          let row = rows[x]
          if(row[0] && row[1] && !isNaN(parseInt(row[1])) && row[2] && !isNaN(parseInt(row[2]))){
            this.importList.push({
              shipmentId: row[0],
              noOfSkus: parseInt(row[1]),
              noOfUnits: parseInt(row[2])
            })
          }
        }
      }
    },
    async onImportSubmit(){
      this.importLoading = true
      try {
        let form = {
          shipmentIds: this.importList
        }
        let response = await api.put(`/api/fba-shipments`, JSON.stringify(form));
        this.$bvToast.toast(response.data.message, {
          title: `Success`,
          variant: 'success',
          solid: true
        })
        this.importLoading = false
        this.onImportCancel();
        await this.loadItems();
      } catch (error) {
        this.$bvToast.toast(error.data.message, {
          title: `Error`,
          variant: 'danger',
          solid: true
        })
        this.importLoading = false
      }
    },
    validateInputs(){
      let IDs = this.importForm.inputs.split(/[\n\s,]+/);
      this.importList = [];
      for(let x in IDs){
        let ID = IDs[x]
        if(ID){
          this.importList.push(ID);
        }
      }
      this.importList = [...new Set(this.importList)]
    },
    async loadItems(){
      this.items = []
      this.tableLoading = true
      let response = await api.get('/api/fba-shipments')
      this.items = response.data
      this.pagination = {
        ...this.pagination,
        totalRows: response.data.length
      }
      this.tableLoading = false
    },
    async onViewResults(data){
      this.resultModalItems = []
      this.resultModal = true
      this.resultModalLoading = true
      this.resultModalData = data
      let response = await api.get(`/api/fba-shipments/batch/${data.batchId}`)
      this.resultModalItems = response.data
      this.resultModalPagination = {
        ...this.resultModalPagination,
        totalRows: response.data.length
      }
      this.resultModalLoading = false
    },
    async onCopyToClipboard(){
      let header = this.resultModalFields.filter(e => e.label).map(e => e.label).join('\t');
      let columns = this.resultModalFields.filter(e => e.label).map(e => e.key);

      let items = [];
      for(let x in this.resultModalItems){
        let resultItem = this.resultModalItems[x]
        if(resultItem.status !== 'open'){
          let row = [];
          let keys = Object.keys(resultItem);
          for(let i in keys){
            let key = keys[i]
            if(columns.includes(key)){
              row.push(resultItem[key])
            }
          }
          items.push(row.join('\t'))
        }
      }
      
      if(items.length === 0){
        this.$bvToast.toast('Unable to copy! All items on this batch are open.', {
          title: 'Warning',
          variant: 'warning',
          solid: true
        })
      }else{
        items.unshift(header)
        let res = items.join('\n')
        await navigator.clipboard.writeText(res);
        this.$bvToast.toast('Copied to Clipboard!', {
          title: `Success`,
          variant: 'success',
          solid: true
        })
      }
    },
    async onDownloadResult(){
      try {
        let sheetname = this.resultModalData.batchId
        let header = this.resultModalFields.filter(e => e.label).map(e => e.label)

        let headerKeys = this.resultModalFields.filter(e => e.label).map(e => e.key)
        
        let rows = []
        for(let x in this.resultModalItems){
          let resultItem = this.resultModalItems[x] 
          if(resultItem.status !== 'open'){
            let obj = {}
            let keys = Object.keys(resultItem);
            for(let i in keys){
              let key = keys[i]

              let headerIndex = headerKeys.indexOf(key)
              if(headerIndex > -1){
                obj[header[headerIndex]] = resultItem[key]
              }
            }
            rows.push(obj)
          }
        }

        if(rows.length === 0){
          this.$bvToast.toast('Unable to download! All items on this batch are open.', {
            title: 'Warning',
            variant: 'warning',
            solid: true
          })
        }else{
          await generateExcel({ header, rows, sheetname }, `${this.resultModalData.batchId}.xlsx`)
        }
      } catch (error) {
        this.$bvToast.toast(error.message, {
          title: `Error`,
          variant: 'danger',
          solid: true
        })
      }
    },
    async onDirectDownload(data){
      this.directDownloadLoading = true
      try {
        let response = await api.get(`/api/fba-shipments/batch/${data.batchId}`)
        let resultModalItems = response.data

        let sheetname = data.batchId
        let header = this.resultModalFields.filter(e => e.label).map(e => e.label)
        let headerKeys = this.resultModalFields.filter(e => e.label).map(e => e.key)
        
        let rows = []
        for(let x in resultModalItems){
          let resultItem = resultModalItems[x] 
          if(resultItem.status !== 'open'){
            let obj = {}
            let keys = Object.keys(resultItem);
            for(let i in keys){
              let key = keys[i]

              let headerIndex = headerKeys.indexOf(key)
              if(headerIndex > -1){
                obj[header[headerIndex]] = resultItem[key]
              }
            }
            rows.push(obj)
          }
        }
        if(rows.length === 0){
          this.$bvToast.toast('Unable to download! All items on this batch are open.', {
            title: 'Warning',
            variant: 'warning',
            solid: true
          })
        }else{
          await generateExcel({ header, rows, sheetname }, `${sheetname}.xlsx`)
        }
      } catch (error) {
        this.$bvToast.toast(error.message, {
          title: `Error`,
          variant: 'danger',
          solid: true
        })
      }finally{
        this.directDownloadLoading = false
      }
    }
  }
}
</script>

<style>
  div.fba-shipment tbody[role="rowgroup"] > tr > td, div.fba-shipment thead[role="rowgroup"] > tr > th{
    font-size: 14px !important;
  }
</style>